import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Modal, Form } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync, faEye, faArrowUpRightFromSquare, faCircleCheck, faTimesCircle, faSpinner, faCircleXmark, faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as ApplicationIcon } from './../assets/images/application-icon.svg';
/* import { ReactComponent as DeniedApplicationIcon } from './../assets/images/denied-application-icon.svg'; */
import { ReactComponent as NewApplicationIcon } from './../assets/images/new-applications-icon.svg';
import { ReactComponent as ApprovedApplicationIcon } from './../assets/images/approved-application-icon.svg';
import UserService from '../services/UserService';
import Header from "../components/Header";
/* import BuyAdditionalCredits from "../components/usersignup/BuyAdditionalCredits.jsx"; */
import ContactUs from "../components/ContactUs.jsx";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import UserRegistration from "../components/usersignup/UserRegistration.jsx";

const serverConfig = require("../config/server.js");
const appConfig = require("../config/config.js");

const CustomerApplications = () => {

  const stripePromise = loadStripe(appConfig.stripe_key);
  const [isNext, setIsNext] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);

  const [token, setToken] = useState(null);
  const getToken = () => {
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt, domain:window.location.hostname})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleLogout = () => {
    UserService.doLogout();
    sessionStorage.clear();
    localStorage.clear();
  }

  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }
  }, []);

  const [applications, setApplications] = useState([]);

  const mToken = sessionStorage.getItem("token");

  // Code for the Applications

  const [applicationCount, setApplicationCount] = useState({});
  const [showApplications, setShowApplications] = useState("all");
  const [kycComplete, setKycComplete] = useState(false);
  const [userInfoComplete, setUserInfoComplete] = useState(false);

  const getCustAppCount = () => {
    axios
    .post(serverConfig.api_base_url + "get_cust_app_count", {
      token: mToken,
    })
    .then((response) => {
      console.log(response);
      if (response.data.status === 200) {
        setApplicationCount(response.data.output);
      } else if (response.data.status === 301) {
        handleLogout();
      } else {
        // alert(response.data.message);
      }
    })
    .catch((err) => console.log("error is", err));
  }

  useEffect(() => {
    if (mToken !== null && mToken !== undefined) {
      axios
      .post(serverConfig.api_base_url + "customer_application_list_get", {
        token: mToken,
      })
      .then((response) => {
        if (response.data.status === 200) {
          console.log("customer_application_list_get", response);
          if(response.data.items !== null){
            const updatedApplications = response.data.items?.filter(el => el.status === "Open")?.reverse().map((app) => ({
              ...app,
              actions: (<>
                <Button type="button" variant="link" title="Continue with Application" onClick={() => handleContinueApplication(app.company_id)}>
                  {/* <Link to={"/company?" + app.company_id} className='ms-2'> */}<FontAwesomeIcon icon={faEye} />{/* </Link> */}
                </Button>
                {app.fincen_complete === "true" && <Button type="button" variant="link" title="Valid">
                  <FontAwesomeIcon icon={faCircleCheck} />
                </Button>}
                {app.fincen_complete === "false" && <Button type="button" variant="link" title="Invalid">
                  <FontAwesomeIcon icon={faCircleExclamation} />
                </Button>}
                {app.fincen_complete === null && <Button type="button" variant="link" title="Not Validated">
                  <FontAwesomeIcon icon={faCircleXmark} />
                </Button>}
                {app.fincen_complete !== "true" && app.fincen_transcript === null && <Button type="button" variant="link" title="Submit to FinCEN" disabled={!(kycComplete && userInfoComplete && app.submitted==="true")} onClick={() => handleFincenSubmit(app.application_id, false)}>
                  <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                </Button>}
              </>),
            }));
            setApplications(updatedApplications);
          }
          setKycComplete(response.data.kycComplete === 1 ? true : false);
          setUserInfoComplete(response.data.userInfoCompleted === 1 ? true : false);
          setTimeout(() => {
            setShowApplications("all");
          }, 100);
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          // alert(response.data.message);
        }
      })
      .catch((err) => console.log("error is", err));

      // Application Counts
      getCustAppCount();
    }
  }, [mToken, /* showApplications, */ kycComplete, userInfoComplete]);

  const refreshAppList = (e) => {
    axios
      .post(serverConfig.api_base_url + "customer_application_list_get", {
        token: mToken,
      })
      .then((response) => {
        console.log("customer_application_list_get", response);
        if (response.data.status === 200) {
          if(response.data.items !== null){
            const updatedApplications = response.data.items?.filter(el => el.status === "Open")?.reverse().map((app) => ({
              ...app,
              actions: (<>
                <Button type="button" variant="link" title="Continue with Application" onClick={() => handleContinueApplication(app.company_id)}>
                  {/* <Link to={"/company?" + app.company_id} className='ms-2'> */}<FontAwesomeIcon icon={faEye} />{/* </Link> */}
                </Button>
                {app.fincen_complete === "true" && <Button type="button" variant="link" title="Valid">
                  <FontAwesomeIcon icon={faCircleCheck} />
                </Button>}
                {app.fincen_complete === "false" && <Button type="button" variant="link" title="Invalid">
                  <FontAwesomeIcon icon={faCircleExclamation} />
                </Button>}
                {app.fincen_complete === null && <Button type="button" variant="link" title="Not Validated">
                  <FontAwesomeIcon icon={faCircleXmark} />
                </Button>}
                {app.fincen_complete !== "true" && app.fincen_transcript === null && <Button type="button" variant="link" title="Submit to FinCEN" disabled={!(kycComplete && userInfoComplete && app.submitted==="true")} onClick={() => handleFincenSubmit(app.application_id, false)}>
                  <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                </Button>}
              </>),
            }));
            setApplications(updatedApplications);
          }
          setKycComplete(response.data.kycComplete === 1 ? true : false);
          setUserInfoComplete(response.data.userInfoCompleted === 1 ? true : false);
          setTimeout(() => {
            setShowApplications("all");
          }, 100);
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          // alert(response.data.message);
        }
      })
      .catch((err) => console.log("error is", err));
  };

  const columns = [
    { label: "Application ID", field: "application_id", },
    { label: "Name of Company", field: "company_name", },
    { label: "FinCEN Status", field: "fincen_status", },
    { label: "Type of Filing", field: "type_of_filing", },
    { label: "Actions", field: "actions" },
  ];

  const data = {
    columns: columns,
    rows: applications,
  };

  const [privilegeSubmitData, setPrivilegeSubmitData] = useState(false);
  const [org, setOrg] = useState(null);

  useEffect(() => {
    if(token !== null && token !== undefined){
      //submit_data - is customer
      axios.post(serverConfig.api_base_url + "user_check_privilege",{token: token, privilege: "submit_data"}).then(response=>{
        console.log('user_check_privilege response', response);
        if(response.data.status === 200) {
          if(response.data.is_privileged === 1){
            setPrivilegeSubmitData(true);
          } else if(response.data.is_privileged === 0) {
            setPrivilegeSubmitData(false);
            navigate("/applications");
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));

      axios.post(serverConfig.api_base_url + "customer_org_get",{token: token}).then(response=>{
        console.log('customer_org_get response', response);
        if(response.data.status === 200) {
          if(response.data.org){
            setOrg(response.data.org);
          } else {
            setOrg(null);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));

      /* axios.post(serverConfig.api_base_url + "customer_entity_group_details_get",{token:token, entity_group:"documentation", application_id:applicationId})
      .then(response=>{
        console.log('customer_entity_group_details_get', response);
        if(response.data.status === 200) {
          console.log(response.data.items);
          if(response.data.items !== null){
            let res = response.data.items;
            let pp = res.group_sections.filter(section => section.section_name === "passport_details");
            if(pp.length > 0){
              let pp_img_name = pp[0].section_entities.filter(entities => entities.entity_name === "passportImg");
              console.log(JSON.parse(pp_img_name[0]?.entity_data));
              if(pp_img_name[0].entity_data){
                setKycComplete(true);
              } else {
                setKycComplete(false);

                axios.post(serverConfig.api_base_url + "get_kyc_token",{token:token, entity_group: "documentation"})
                .then(response=>{
                  console.log(response);
                  if(response.data.status === 200) {
                    console.log('success', window.location);
                    let url = window.location.origin+"/kyc-validation?refid="+response.data.kyc_token+"&appid="+applicationId;
                    console.log(url);
                    //setVerifyIDUrl(url);
                  } else if(response.data.status === 301){
                    handleLogout();
                  } else {
                    alert(response.data.message);
                  }
                })
                .catch(err=>console.log("error is",err));
              }
            }
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err)); */
    }
  }, [token]);

  const [showBulkUpload, setShowBulkUpload] = useState(false);
  const [fincenSubmitCompleted, setFincenSubmitCompleted] = useState(false);
  const [closeDisabled, setCloseDisabled] = useState(false);
  const [fileName, setFileName] = useState("");
  const [attachmentFileNames, setAttachmentFileNames] = useState([]);
  const [appIds, setAppIds] = useState([]);
  const [appIdCount, setAppIdCount] = useState(null);
  const [rejectedFile, setRejectedFile] = useState("");

  const handleUpload = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    console.log(event.target.accept);
    //console.log(value);
    var extension = value.split('.').pop();
    //console.log(extension);

    if(event.target.accept === '.xlsx'){
      if(extension=='xlsx'){
        setIsLoading(true);
        console.log("File type matched");
        var file = document.querySelector('#'+event.target.id).files[0];
        console.log(file.size);
        let fileSizeInMB = file.size  / 1048576;
        console.log(fileSizeInMB);
  
        if(file !== undefined) {
          let filename = file.name;
          //setFileName(filename);
          var fileData;
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function () {
            fileData = reader.result;
            if(fileSizeInMB <= 10){
              console.log(file);

              const formData = new FormData();
              formData.append("file", file, file.name); // You can specify the name as you need.

              const headers = {
                "token": token,
              };

              axios.post(serverConfig.fapi_fincen + "fincen_bulk_upload", formData, { headers })
              .then((response) => {
                console.log(response);
                console.log(response.data); // Handle response data here
                if(response.data.status === 200){
                  if(response.data.application_ids.length > 0){
                    setCloseDisabled(true);
                    setFileName(filename);
                  } else {
                    setCloseDisabled(false);
                    setFileName("");
                    alert("No records uploaded. Please check for duplicates or Cancel and Bulk Upload again.");
                  }
                  
                  console.log(response.data.attachment_filenames, [...new Set(response.data.attachment_filenames)]);
                  setAttachmentFileNames([...new Set(response.data.attachment_filenames)]);
                  setAppIds(response.data.application_ids);
                  setAppIdCount(response.data.application_ids.length);
                  //setRejectedFile(response.data.rejects_filename);
                  refreshAppList();
                  setIsLoading(false);
                  event.target.value = '';
                  getCustAppCount();
                } else {
                  setCloseDisabled(false);
                  setFileName("");
                  setAttachmentFileNames([]);
                  setAppIds([]);
                  setAppIdCount(null);
                  //setRejectedFile("");
                  setIsLoading(false);
                  event.target.value = '';
                  alert(response.data["template_invalid"]);
                  getCustAppCount();
                }
              })
              .catch((error) => {
                console.error(error); // Handle any errors here
              });
            } else {
              setIsLoading(false);
              alert('File size exceeds maximum limit of 10MB. Please try again.');
              event.target.value = '';
            }
          };
          reader.onerror = function (error) {
            console.log('Error: ', error);
          };
        }
      } else {
        alert("Only .xlsx file is allowed");
        event.target.value = null;
      }
    }
  }

  const [idFileIndex, setIdFileIndex] = useState(0);
  const [IdFileName, setIdFileName] = useState("");
  const [fileNameErrorMsg, setFileNameErrorMsg] = useState("");
  const [fileSizeErrorMsg, setFileSizeErrorMsg] = useState("");
  const handleUploadId = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    console.log(event.target.accept);
    //console.log(value);
    var extension = value.split('.').pop();
    //console.log(extension);

    if(event.target.accept === '.pdf, .jpeg, .jpg, .png'){
      if(extension=='pdf' || extension=='jpeg' || extension=='jpg' || extension=='png'){
        setIsLoading(true);
        console.log("File type matched");
        const files = Array.from(event.target.files);

        // Convert files to Blobs with additional metadata
        const processedFiles = files.map(file => ({
          name: file.name,
          blob: new Blob([file], { type: file.type }),
          path: file.webkitRelativePath, // Preserve original path
          size: file.size,
          type: file.type
        }));
        
        console.log(files, processedFiles, attachmentFileNames);
  
        if(processedFiles.length > 0) {
          if(processedFiles.length > 1){//if more than 1 file
            setIdFileName(processedFiles.length+" files selected");
          } else {//if only 1 file
            let filename = processedFiles[0].name;
            setIdFileName(filename);
          }

          // Optional: Prepare files for upload
          const formData = new FormData();
          const headers = {
            "token": token,
          };

          var fileindex=0;
          processedFiles.forEach((file, index) => {
            console.log(file, file.blob, file.name, file.size, index);
            //setIdFileIndex(index);
            if(file.size / 1048576 <= 5){
              if(attachmentFileNames.includes(file.name)){
                formData.append("file", file.blob, file.name);

                // Here you would typically call your upload API
                axios.post(serverConfig.fapi_fincen + "fincen_bulk_attachment_upload", formData, { headers })
                .then((response) => {
                  if(response.data.status === 200){
                    console.log("success", response); // Handle response data here
                    setIsLoading(false);
                    event.target.value = '';
                    fileindex=fileindex+1;
                    console.log(fileindex);
                    setIdFileIndex(fileindex);
                    //setFileName("");
                    //setIdFileName("");
                    //setAttachmentFileNames([]);
                    //setShowBulkUpload(false);
                  } else {
                    console.log("error", response); // Handle response data here
                    alert(response.data.message);
                    setIsLoading(false);
                    event.target.value = '';
                    setIdFileName("");
                    //setFileName("");
                    //setAttachmentFileNames([]);
                    //setShowBulkUpload(false);
                  }
                })
                .catch((error) => {
                  console.error(error); // Handle any errors here
                });
              } else {
                setIsLoading(false);
                console.log('The file names of the ID documents should match the file names specified in the uploaded spreadsheet. Please try uploading again.');
                setFileNameErrorMsg('The file names of the ID documents should match the file names specified in the uploaded spreadsheet. Please try uploading again.');
                event.target.value = '';
              }
            } else {
              setIsLoading(false);
              //console.log('One/more of the file size exceeds maximum limit of 5MB. Please try uploading again.');
              setFileSizeErrorMsg('One/more of the file size exceeds maximum limit of 5MB. Please try uploading again.');
              event.target.value = '';
            }
          });

          

          //var fileArr = [];
          /* for(var i=0; i < files.length; i++){
            console.log(i, files.length);
            //var fileData;
            let fileData;
            let filename = files[i].name;
            console.log(filename, files[i], files[i].size, i);

            if (attachmentFileNames.includes(filename)) {//file name is present in the response array. Upload only filename that are present/match in excel sheet
              //console.log(filename,' is present');
              let fileSizeInMB = files[i]?.size  / 1048576;
              console.log(fileSizeInMB);
              if(fileSizeInMB <= 5){
                var reader = new FileReader();
                reader.readAsDataURL(files[i]);
                reader.onload = function () {
                  fileData = reader.result;
                  //after upload code goes here
                  console.log(reader, filename, fileData);

                  //call api
                  const formData = new FormData();
                  formData.append("file", files[i], filename); // You can specify the name as you need.

                  const headers = {
                    "token": token,
                  };

                  axios.post(serverConfig.fapi_fincen + "fincen_bulk_attatchment_upload", formData, { headers })
                  .then((response) => {
                    console.log("success", response); // Handle response data here
                  })
                  .catch((error) => {
                    console.error(error); // Handle any errors here
                  });

                  if(i = files.length - 1){
                    console.log("end of array");
                    setIsLoading(false);
                    event.target.value = '';
                    setFileName("");
                    setIdFileName("");
                    setAttachmentFileNames([]);
                    setShowBulkUpload(false);
                  }
                  //fileArr.push({filename: filename, fileBase64: fileData});
                  //console.log(fileArr);
                };
                reader.onerror = function (error) {
                  console.log('Error: ', error);
                };
              } else {
                setIsLoading(false);
                alert('File size exceeds maximum limit of 5MB. Please try again.');
                event.target.value = '';
              }
            } else {
              //console.log(filename,' is NOT present');
            }
          } */
        } else {
          setIsLoading(false);
          console.log('The file names of the ID documents should match the file names specified in the uploaded spreadsheet. Please try uploading again.');
          setFileNameErrorMsg('The file names of the ID documents should match the file names specified in the uploaded spreadsheet. Please try uploading again.');
          event.target.value = '';
        }
      } else {
        alert("Only .pdf, .jpeg, .jpg, .png file is allowed");
        event.target.value = null;
      }
    }
  }

  const handleSingleApplication = () => {
    axios.post(serverConfig.api_base_url + "application_create",{token:token, for_bulk_upload: 0})
    .then(response=>{
      console.log('application_create response', response);
      if(response.data.status === 200) {
        console.log(response.data);
        if(response.data.companyId !== null){
          navigate("/company?" + response.data.companyId);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleContinueApplication = (companyId) => {
    console.log(kycComplete, userInfoComplete);
    if(kycComplete && userInfoComplete) {
      setErrorMessage(null);
      navigate("/company?" + companyId);
    } else {
      setErrorMessage(<>Please complete KYC / User Information first by clicking on <b>Submit KYC</b> button</>);
    }
  }

  const [showAddCredits, setShowAddCredits] = useState(false);
  const handleCloseAddCredits = () => {
    setShowAddCredits(false);
  }

  const [btnLoading, setBtnLoading] = useState(false);
  const [showCredits, setShowCredits] = useState(false);
  const handleGetCredits = () => {
    if(!showCredits){
      setBtnLoading(true);
      axios.post(serverConfig.api_base_url + "user_application_credits_get",{token:token})
      .then(response=>{
        console.log('user_application_credits_get response', response);
        
        if(response.data.status === 200) {
          console.log(response.data);
          setShowCredits(true);
          setBtnLoading(false);
          getCustAppCount();
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    } else {
      setShowCredits(false);
    }
  }

  const handleSubmitToFincen = () => {
    appIds.forEach((appid, index) => {
      handleFincenSubmit(appid, index === appIds.length - 1 ? true : false);

      /* if(index === appIds.length - 1){//once last application is submitted
        alert("Submit to FinCEN successful");
        setFincenSubmitCompleted(true);
        setCloseDisabled(false);
      } */
    });
  }

  const handleFincenSubmit = (appid, isLastApp) => {
    setIsLoading(true);
    axios.post(serverConfig.fapi_fincen + "fincen_submit",{token:token, application_id:appid})
    .then(response=>{
      console.log('fincen_submit', response);
      if(isLastApp){
        setFincenSubmitCompleted(true);
        setCloseDisabled(false);
        setFileName("");
        setIdFileName("");
        document.getElementById("fincenTemplateData").disabled=true;
        alert("Submit to FinCEN successful");
        setIsLoading(false);
        refreshAppList();
        axios.post(serverConfig.fapi_fincen + "fincen_generate_validation_report",{token:token})
        .then(response=>{
          console.log('fincen_generate_validation_report', response);
          if(response.data.status === 200){
            setRejectedFile(response.data.rejects_filename);
          } else{
            setRejectedFile("");
          }
        })
        .catch(err=>console.log("error is",err));
      } else {
        refreshAppList();
        setIsLoading(false);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleCloseMultiple = () => {
    setFileName(""); setIdFileName(""); setAttachmentFileNames([]); setAppIds([]); setAppIdCount(null); setCloseDisabled(false); setRejectedFile(""); setIdFileIndex(0); setShowBulkUpload(false); setFincenSubmitCompleted(false); setFileNameErrorMsg(""); setFileSizeErrorMsg("");
  }

  return (
    privilegeSubmitData && <div>

      {isLoading && (<div id='spinner'></div>)}

      {errorMessage && <div id='loading'><div className='loading-wrap bg-white'><span className="error-icon"></span><div>{errorMessage}<br/><button type="button" className='btn btn-primary' onClick={() => setErrorMessage(null)}>Close</button></div></div></div>}

      <Header isLoggedIn={(token !== null && token !== undefined && token !== '') ? true : false} />
      <main>
        <Container>
        <div>
          <div className="page-heading">
            <h1>Applications</h1>
          </div>
          <>
            <section className="pt-4">
              <Row className="application-stats">
                <Col>
                  <div
                    className="inner"
                    onClick={() => setShowApplications("total_credits")}
                  >
                    <h4>Available Subscription</h4>
                    <div className="count-wrap">
                      <span className="count">
                        <span>
                          {applicationCount.total_credits ? applicationCount.total_credits : "0"}
                        </span>
                        Nos.
                      </span>
                      <span>
                        <ApplicationIcon />
                      </span>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div
                    className="inner"
                    onClick={() => setShowApplications("submitted")}
                  >
                    <h4>Submitted Applications</h4>
                    <div className="count-wrap">
                      <span className="count">
                        <span>
                          {applicationCount.submitted ? applicationCount.submitted : "0"}
                        </span>
                        Nos.
                      </span>
                      <span>
                        <ApprovedApplicationIcon />
                      </span>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div
                    className="inner"
                    onClick={() => setShowApplications("in_progress")}
                  >
                    <h4>In progress Applications</h4>
                    <div className="count-wrap">
                      <span className="count">
                        <span>
                          {applicationCount.in_progress ? applicationCount.in_progress : "0"}
                        </span>
                        Nos.
                      </span>
                      <span>
                        <NewApplicationIcon />
                      </span>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div
                    className="inner"
                    onClick={() => setShowApplications("open")}
                  >
                    <h4>Open Applications</h4>
                    <div className="count-wrap">
                      <span className="count">
                        <span>
                          {/* {applicationCount.open ? applicationCount.open : "0"} */}
                          {applicationCount?.open - (applicationCount?.submitted + applicationCount?.in_progress)}
                        </span>
                        Nos.
                      </span>
                      <span>
                        <NewApplicationIcon />
                      </span>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div
                    className="inner"
                    onClick={() => setShowApplications("spent_credits")}
                  >
                    <h4>Spent Subscription</h4>
                    <div className="count-wrap">
                      <span className="count">
                        <span>
                          {applicationCount.spent_credits ? applicationCount.spent_credits : "0"}
                        </span>
                        Nos.
                      </span>
                      <span>
                        <ApplicationIcon />
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
            </section>
            <section>
              <div className="mt-2 d-flex gap-4 flex-wrap">
                {(kycComplete && userInfoComplete) ? <div className="txt-green" style={{flex: "1 0 100%"}}>
                  <FontAwesomeIcon icon={faCircleCheck} /> KYC and User Information completed
                </div> : <Button type="button" onClick={() => navigate("/customer")}>Submit KYC</Button>
                }
                
                <Button type="button" onClick={handleSingleApplication} disabled={!(kycComplete && userInfoComplete)}>Apply Now</Button>
                <Button type="button" onClick={() => {applicationCount?.total_credits > 0 ? setShowBulkUpload(true) : alert("Subscription limit exhausted. Please buy additional credits to continue");}} disabled={!(kycComplete && userInfoComplete)}>Bulk Upload</Button>
                <Button type="button" onClick={() => setShowAddCredits(true)}>Buy Additional Credits</Button>
                <Button type="button" onClick={handleGetCredits} className={btnLoading ? "btnloading" : undefined}>{showCredits ? <>{applicationCount?.total_credits} credits <FontAwesomeIcon icon={faTimesCircle} className="ms-1" /></> : <>Show Credits {btnLoading && <FontAwesomeIcon icon={faSpinner} className="ms-1" />}</>}</Button>

                <Modal show={showAddCredits} onHide={handleCloseAddCredits} centered backdrop="static" keyboard={false} dialogClassName="mw-500">
                  <Modal.Header closeButton><h4>Buy Additional Credits</h4></Modal.Header>
                  <Modal.Body>
                    {/* <BuyAdditionalCredits addCredits={true} org={org} /> */}
                    <Elements stripe={stripePromise}><UserRegistration addCredits={true} org={org} sendIsNextVal={setIsNext} getCustAppCount={getCustAppCount} /></Elements>
                  </Modal.Body>
                </Modal>
              </div>
            </section>
            {showBulkUpload && <section className="mt-5">
              <h4 className="mb-3">
                Bulk Upload
                <Button disabled={closeDisabled} type="button" variant="transparent" className="ms-3" onClick={handleCloseMultiple}>Cancel</Button>
              </h4>
              <div className="mb-4">
                <p><b>The Fincen Bulk Import Data should be filled in the provided template and uploaded.</b></p>
                <p>Please follow the instructions below:</p>
                <ol>
                  <li>Click on the below link to download the template. The file will be downloaded into your 'Downloads' folder. Open the folder.<br/>
                  <a href="/templates/Fincen_Bulk_Import_Template.xlsx" download="" className="txt-link">Download Fincen Bulk Import Template</a></li>
                  <li>Open the file in the folder.</li>
                  <li>Fill in the Fincen Bulk Import Data
                    <ol type="a">
                      <li>You can use this template to load more than 1 company BOIR at a single time.</li>
                      <li>Please use "Template" tab (bottom of the template) to fill up your BOIR.</li>
                      <li>If a company has more than 1 Company Applicant or more than 5 Beneficial Owner or is an Exempt entity, please use the single application flow. This template will fail at such instances.</li>
                      <li>Please check for the color code on the Template to understand what each section represents.</li>
                      <li>You can add any number of companies on this template, but do not go beyond the credits that you have purchased.</li>
                    </ol>
                  </li>
                  <li>Place ID documents of all Company Applicants and all Beneficial Owners of all companies in a folder. Each document file size should not exceed 5MB and must be in one of the following formats: JPG/JPEG, PNG, or PDF. For each Company Applicant / Beneficial Owner, enter the appropriate document file name in the column provided.</li>
                  <li>
                    Upload the spreadsheet here.
                    <div className="position-relative">
                      <Form.Label htmlFor="fincenTemplateData" className='upload'>
                        {fileName ? <div className="d-flex gap-5 justify-content-center align-items-center">
                          <b>{fileName}</b>Tap to upload again
                        </div> : <>Drag and Drop the file here or <u>Browse</u>. [Max 10MB]</>}
                      </Form.Label>
                      <Form.Control id="fincenTemplateData" name="fincenTemplateData" type="file" accept=".xlsx" onChange={handleUpload}  />
                    </div>
                    <div>Note:The maximum file size should be 10MB</div>
                    {appIdCount === null ? "" : <div><b>{appIdCount > 0 ? appIdCount : "No"} records were saved.</b></div>}
                  </li>
                  <li>
                     At this point, the folder with all ID documents mentioned in step 4 should be ready. The file names of the ID documents should match the file names specified in the uploaded spreadsheet.
                    <br/>Ready? Select the folder below
                    <div className="position-relative">
                      <Form.Label htmlFor="documentId" className='upload'>
                        {IdFileName ? <div className="d-flex gap-5 justify-content-center align-items-center">
                          <b>{IdFileName}</b>Tap to upload again
                        </div> : <>Drag and Drop the folder here or <u>Browse</u>.</>}
                      </Form.Label>
                      <Form.Control id="documentId" name="documentId" type="file" accept=".pdf, .jpeg, .jpg, .png" onChange={handleUploadId} directory="" webkitdirectory="" multiple disabled={fileName ? false : true} />
                    </div>
                  </li>
                  {(rejectedFile && fincenSubmitCompleted) && <li>Some records were rejected. Click below link to retrieve:<br/>
                    <a href={serverConfig.fapi_fincen+"fincen_get_rejects_file?filename="+rejectedFile} download="" className="txt-link">Download rejected records file</a>
                  </li>}
                </ol>
              </div>
              {attachmentFileNames.length > 0 && <div className="mb-4">
                <p>File {idFileIndex === 0 ? 0 : idFileIndex} of {attachmentFileNames.length} uploaded</p>
                {(fileNameErrorMsg && attachmentFileNames.length !== idFileIndex) && <div className="txt-red"><small>{fileNameErrorMsg}</small></div>}
                {(fileSizeErrorMsg && attachmentFileNames.length !== idFileIndex) && <div className="txt-red"><small>{fileSizeErrorMsg}</small></div>}
              </div>}
              <div className="d-flex gap-4">
                {/* <Button type="button" disabled={!fileName || !IdFileName}>Validate</Button> */}
                <Button type="button" disabled={!fileName || !IdFileName || fincenSubmitCompleted} onClick={handleSubmitToFincen}>Submit to FinCEN</Button>
                <Button type="button" disabled={closeDisabled} variant="transparent" onClick={handleCloseMultiple}>Close</Button>
              </div>
            </section>}
            <section>
              <div className="datatable datatable-app-list">
                <h2>
                  Total Applications{" "}
                  <button className="ms-3" onClick={refreshAppList}>
                    <FontAwesomeIcon icon={faSync} />
                  </button>
                </h2>
                <MDBDataTable striped bordered hover data={data} />
              </div>
            </section>
          </>
        </div>
        </Container>
        <ContactUs />
      </main>
    </div>
  );
};

export default CustomerApplications;
