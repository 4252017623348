import React, {useState, useEffect} from 'react';
import { Container, Button, Form } from 'react-bootstrap';
import { IdValidation, FaceValidation } from 'idmission-web-sdk';
import { Link } from 'react-router-dom';
import { QRCode } from 'react-qrcode-logo';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import axios from "axios";
import Header from '../components/Header';
import UserService from '../services/UserService.js';

const serverConfig = require("../config/server.js");

const KYCValidation = () => {

  const urlParams = new URLSearchParams(window.location.search);
  const kyc_token = urlParams.get('refid');

  const applicationId = urlParams.get('appid');

  const[verifyIDUrl, setVerifyIDUrl] = useState(window.location.href);
  console.log(window.location.href);

  const [token, setToken] = useState(null);
  const[ppChecks, setPPChecks] = useState({});
  const[inputs, setInputs] = useState({});

  const [isLoading, setIsLoading] = useState(true);
  const[kycTokenValid, setKycTokenValid] = useState(false);
  const[validationSuccess, setValidationSuccess] = useState(false);

  const[idmToken, setIdmToken] = useState(null);
  const[startFaceValidation, setStartFaceValidation] = useState(false);
  const[startPPValidation, setStartPPValidation] = useState(false);
  const[startSecDocValidation, setStartSecDocValidation] = useState(false);

  const[countryLookup, setCountryLookup] = useState([]);

  const[errorSelfie, setErrorSelfie] = useState(false);
  const[errorPassport, setErrorPassport] = useState(false);
  const[errorPassportDetails, setErrorPassportDetails] = useState(false);
  const[successPassportDetails, setSuccessPassportDetails] = useState(false);
  const[errorSecDoc, setErrorSecDoc] = useState(false);

  const[kycMsg, setKycMsg] = useState("");
  const[nextDisabled, setNextDisabled] = useState(false);
  
  const handleStartPPValidation = () => {
    setStartFaceValidation(false);
    setStartPPValidation(true);
  }

  const kycStatusGet = () => {
    axios.post(serverConfig.api_base_url + "customer_kyc_status_get",{token:token, application_id:applicationId})
    .then(response=>{
      console.log("customer_kyc_status_get response:", response);
      console.log(response.data.status);
      if(response.data.status === 200){
        if(response.data.kyc_rejected === 1){
          setNextDisabled(true);
          setKycMsg(response.data.kyc_message);
        } else if(response.data.kyc_rejected === 0){
          setNextDisabled(false);
          setKycMsg("");
        }
      } else if(response.data.status === 407){
        //handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
    axios.post(serverConfig.api_base_url + "get_kyc_token_validity",{token:kyc_token})
    .then(response => {
      console.log('get_kyc_token_validity response', response);
      if(response.status === 200){
        if(response.data.validity === 1){
          setKycTokenValid(true);
          setToken(response.data.userToken);
          setIsLoading(false);

          axios.post("https://chia.dev.mtk.dbtez.net/api/get_idmission_token_v2")
          .then(response=>{
            console.log(response);
            if(response.data.access_token) {
              setIdmToken(response.data.access_token);
            } else {
              alert(response.data.message);
            }
          })
          .catch(err=>console.log("error is",err));

          axios.post(serverConfig.api_base_url + "customer_kyc_status_get",{token:response.data.userToken, application_id:applicationId})
          .then(response=>{
            console.log("customer_kyc_status_get response:", response);
            console.log(response.data.status);
            if(response.data.status === 200){
              if(response.data.kyc_rejected === 1){
                setNextDisabled(true);
                setKycMsg(response.data.kyc_message);
              } else if(response.data.kyc_rejected === 0){
                setNextDisabled(false);
                setKycMsg("");
              }
            } else if(response.data.status === 407){
              //handleLogout();
            } else {
              alert(response.data.message);
            }
          })
          .catch(err=>console.log("error is",err));
        } else {
          setKycTokenValid(false);
          setIsLoading(false);
        }
      }
    })
    .catch(err=>console.log("error is",err));

    axios.post(serverConfig.api_base_url + "get_lookup_values",{entity_name:"country"})
    .then(response=>{
      console.log("Country lookup:", response);
      if (response.data != null && response.data.data != null) {
        console.log("Country data:", response.data.data);
        setCountryLookup(response.data.data/* .sort((a, b) => a.value.localeCompare(b.value)) */);
      }
    })
    .catch(err=>console.log("error is",err));
  }, []);

  return (<div className="body-wrap bg-color8 pb-3">
    <Header title="ID Validation" />
    <main>
      <Container fluid>
        <BrowserView>
          <h1 className='text-center'><small>This page is designed for mobile only use. <br/> Scan the following QR code in the mobile to access this page.</small></h1>
          <div className='text-center'><QRCode value={verifyIDUrl} size="200" /></div>
        </BrowserView>
        <MobileView id='kyc-content'>
          {isLoading ? <div id='spinner'></div>
            : <>
              {errorSelfie && <div id='loading'><div className='loading-wrap bg-white'><span className="error-icon"></span><div>Error capturing selfie. Please retry<br/><button type="button" className='btn btn-primary' onClick={() => setErrorSelfie(false)}>Close</button></div></div></div>}

              {errorPassport && <div id='loading'><div className='loading-wrap bg-white'><span className="error-icon"></span><div>Error capturing Primary Document. Please retry<br/><button type="button" className='btn btn-primary' onClick={() => setErrorPassport(false)}>Close</button></div></div></div>}

              {errorPassportDetails && <div id='loading'><div className='loading-wrap bg-white'><span className="error-icon"></span><div>Couldn't read primary document details. Please try again<br/><button type="button" className='btn btn-primary' onClick={() => setErrorPassportDetails(false)}>Close</button></div></div></div>}

              {successPassportDetails && <div id='loading'>
                <div className='loading-wrap bg-white'>
                  <div className="mb-2"><b>Primary Document Details</b></div>
                  <div>
                    <div className="d-flex justify-content-between">ID Number: <b>{inputs.passportNumber}</b></div>
                    {/* <div className="d-flex justify-content-between">Country Code: <b>{inputs.countryOfIssuance}</b></div> */}
                    <div className="d-flex justify-content-between">Issue Date: <b>{inputs.IssuanceDate}</b></div>
                    <div className="d-flex justify-content-between">Expiration Date: <b>{inputs.expirationDate}</b></div>
                    <button type="button" className='btn btn-primary' onClick={() => setSuccessPassportDetails(false)}>Close</button>
                  </div>
                </div>
              </div>}
              
              <div id="thankyou-wrapper" className='thankyou-wrapper'>
                <div>
                  <img src={require('./../assets/images/thumbs-up-icon.png')} alt="Thank you" className='mb-3' />
                  <p>Thank you for completing <br/>your ID verification</p>
                </div>
                <div>
                  {/* <Button variant='transparent' onClick={() => window.open('','_self').close()} className='mx-auto'>Close</Button> */}
                  <p className='txt-gray mt-3'>Please click on "Refresh" button in the desktop to continue</p>
                </div>
              </div>

              <div id="button-content" className="mt-5">
                {kycTokenValid ? <>
                  <Form.Label>Select document type <span className='txt-red'>*</span></Form.Label>
                  <div className="d-flex flex-column mb-5">
                    <Form.Check inline type="radio" name="primaryDocument" value={"Passport" || ""} onChange={(e) => {setInputs({...inputs, primaryDocument: e.target.value})}} id="primaryDocumentPassport" label="Passport" disabled={kycMsg} />
                    <Form.Check inline type="radio" name="primaryDocument" value={"Driver's License" || ""} onChange={(e) => {setInputs({...inputs, primaryDocument: e.target.value})}} id="primaryDocumentDriversLicense" label="Driver's License" disabled={kycMsg} />
                    <Form.Check inline type="radio" name="primaryDocument" value={"State issued ID" || ""} onChange={(e) => {setInputs({...inputs, primaryDocument: e.target.value})}} id="primaryDocumentStateIssuedID" label="State issued ID" disabled={kycMsg} />
                  </div>
                  <Form.Label>Click Picture <span className='txt-red'>*</span></Form.Label>
                  <Button variant="click" type="button" id="captureSelfiePassport" className="w-100" onClick={() => setStartFaceValidation(true)} disabled={kycMsg}>
                                      <span>
                                        <img src={require('./../assets/images/camera-icon.png')} className="me-2" />
                                        <span>Take selfie and picture of your ID</span>
                                      </span>
                                    </Button>
                  {kycMsg && <div dangerouslySetInnerHTML={{__html: kycMsg}} />}
                </>
                : <p className='txt-red text-center'>Link is invalid. To re-initiate, go back to the application and refresh the page.</p>
                }
              </div>

              {startFaceValidation && <div className="validation-popup">
                <FaceValidation 
                  submissionToken={idmToken} 
                  onExitCapture={() => setStartFaceValidation(false)}
                  onDenied={(submissionResponse, submissionRequest) => {
                    axios.post(serverConfig.api_base_url + "customer_kyc_data_set",{customer_name: UserService.getWelcomeName(), customer_email: UserService.getUsername(),
                      token: token,
                      application_id: applicationId,
                      title: "KYC Failed",
                      desc: submissionResponse.resultData.verificationResult,
                      kyc_data: submissionResponse
                    })
                    .then(response=>{
                      console.log('customer_kyc_data_set response', response);
                      if(response.data.status === 200) {
                        console.log("success");
                        kycStatusGet();
                      } else if(response.data.status === 407 || response.data.status === 301){
                        //handleLogout();
                      } else {
                        alert(response.data.message);
                      }
                    })
                    .catch(err=>console.log("error is",err));
                    setErrorSelfie(true);
                  }}
                  onComplete={(submissionResponse, submissionRequest) => {
                    console.log('Your Selfie has been validated, proceeding to PP validation.', submissionResponse);
                    console.log('submissionRequest', submissionRequest);
                    setInputs({
                      ...inputs,
                      selfieImg: submissionRequest.customerData.biometricData.selfie,
                      selfieImgTimestamp: new Date()
                    });
                    handleStartPPValidation();
                  }} 
                  colors={
                    {
                      loadingOverlay:{
                        continueBtn: {
                          backgroundColor: 'var(--primary)',
                          textColor: '#000000'
                        }
                      }
                    }
                  }
                  geolocationEnabled={false}
                  geolocationRequired={false}
                />
              </div>}

              {startPPValidation && <div className="validation-popup">
                <IdValidation 
                  submissionToken={idmToken} 
                  //idAutoCaptureEnabled={false}
                  idCaptureRequirement="idCardOrPassport"
                  //idCardAutoCaptureScoreThreshold="0.01"
                  //idCardFocusScoreThreshold="0.01"
                  //passportAutoCaptureScoreThreshold="0.01"
                  //passportFocusScoreThreshold="0.01"
                  idBackImageRequired={true}
                  onExitCapture={() => {setStartFaceValidation(false); setStartPPValidation(false)}}
                  //onDenied={() => {setErrorPassport(true);}} 
                  onComplete={(submissionResponse, submissionRequest) => {
                    console.log('inputs', inputs);
                    console.log('Your ID has been validated', submissionResponse);
                    console.log('submissionRequest', submissionRequest);
                    //alert('Your PP has been validated, proceeding to PP response evaluation.');

                    if ('FAIL' === submissionResponse.status || submissionResponse.status.statusCode != "000" || submissionResponse.resultData.verificationResult !== "Approved") {
                      console.log("Error capturing primary ID and Selfie. Please retry");
                      setErrorPassport(true);
                      setStartPPValidation(false);

                      axios.post(serverConfig.api_base_url + "customer_kyc_data_set",{customer_name: UserService.getWelcomeName(), customer_email: UserService.getUsername(),
                        token: token,
                        application_id: applicationId,
                        title: "KYC Failed",
                        desc: submissionResponse.resultData.verificationResult,
                        kyc_data: submissionResponse
                      })
                      .then(response=>{
                        console.log('customer_kyc_data_set response', response);
                        if(response.data.status === 200) {
                          console.log("success");
                          kycStatusGet();
                        } else if(response.data.status === 407 || response.data.status === 301){
                          //handleLogout();
                        } else {
                          alert(response.data.message);
                        }
                      })
                      .catch(err=>console.log("error is",err));

                    } else {
                      console.log('Primary ID and Selfie captured successfully.');
                      let extractedData = submissionResponse.responseCustomerData.extractedIdData;
                      let extractedPersonalData = submissionResponse.responseCustomerData.extractedPersonalData;
                      let parsedAddress = submissionResponse.responseCustomerData.parsedAddress;

                      var us_citizen, pep, watchlist, negative_media, countryOfIssuance;
                      var inputVal={};
                      //var msg = '';

                      if (extractedData.idNumber != null && extractedData.idIssueDate != null && extractedData.idIssueCountry != null && extractedData.idExpirationDate != null) {
                        //alert(extractedData.idNumber, extractedData.idIssueDate, extractedData.idIssueCountry, extractedData.idExpirationDate);
                        console.log('PP',extractedData.idNumber);
                        console.log('idIssueDate',extractedData.idIssueDate);
                        console.log('idIssueCountry',extractedData.idIssueCountry);
                        console.log('idExpirationDate',extractedData.idExpirationDate);
                        
                        /* let options = document.querySelectorAll('#countryOfIssuance option');
                        for(var i = 0; i < options.length; i++) {
                          var option = options[i];
                          if(option.getAttribute('data-value-iso3') === extractedData.idIssueCountry) {
                            console.log(option.getAttribute('data-value-iso3'));
                            console.log(option.innerText);
                            //$('input[name="countryOfIssuance"]').val(option.innerText); // assign country name
                            countryOfIssuance=option.innerText;
                            break;
                          }
                        } */

                        inputVal={
                          captureSelfiePassport: submissionResponse,
                          passportNumber: extractedData.idNumber,
                          //IssuanceDate: new Date(extractedData.idIssueDate).toISOString().substring(0, 10),
                          IssuanceDate: (extractedData.idIssueDate).split("/").reverse().join("-"),
                          countryOfIssuance: countryOfIssuance,
                          countryOfIssuanceCode: extractedData.idIssueCountry,
                          //expirationDate: new Date(extractedData.idExpirationDate).toISOString().substring(0, 10)
                          expirationDate: (extractedData.idExpirationDate).split("/").reverse().join("-"),
                          passportImg: submissionRequest.customerData.idData.idImageFront,
                          passportImgTimestamp: new Date(),
                        }

                        setInputs({
                          ...inputs,
                          ...inputVal
                        });

                        console.log('inputVal', inputVal);

                        if(extractedData.idIssueCountry === "US"){
                          us_citizen = "1";
                        } else {
                          us_citizen = "0";
                        }
                        setInputs((stateInputs) => {
                          console.log(inputs, stateInputs);
                          return stateInputs;
                        });
                        setSuccessPassportDetails(true);
                        document.getElementById('thankyou-wrapper').style.display = "flex";
                        document.getElementById('button-content').style.display = "none";
                      } else {
                        //alert('null');
                        axios.post(serverConfig.api_base_url + "customer_kyc_data_set",{customer_name: UserService.getWelcomeName(), customer_email: UserService.getUsername(),
                          token: token,
                          application_id: applicationId,
                          title: "KYC Failed",
                          desc: "Missing one of the primary entities: idNumber or idIssueDate or idIssueCountry or idExpirationDate",
                          kyc_data: submissionResponse
                        })
                        .then(response=>{
                          console.log('customer_kyc_data_set response', response);
                          if(response.data.status === 200) {
                            console.log("success");
                            kycStatusGet();
                          } else if(response.data.status === 407 || response.data.status === 301){
                            //handleLogout();
                          } else {
                            alert(response.data.message);
                          }
                        })
                        .catch(err=>console.log("error is",err));
                        setErrorPassportDetails(true);
                      }

                      console.log(submissionResponse);
                      let hostData = submissionResponse.responseCustomerData.hostData;
                      if(hostData != null){
                        console.log('pep', hostData.pepresult.resultCountPEP);
                        console.log('watchlist', hostData.wlsresult.resultCountWLS);
                        console.log('negative media', hostData.nmresult.resultCountNM);
                        
                        if(submissionResponse.resultData.verificationResultCode == '00' && hostData.pepresult.resultCountPEP == '0' && hostData.wlsresult.resultCountWLS == '0'/*  && hostData.nmresult.resultCountNM == '0' */){
                          console.log('kyc_approved', 'true');
                          inputVal={
                            ...inputVal,
                            kycApproved:'true'
                          }
                        } else {
                          console.log('kyc_approved', 'false');
                          inputVal={
                            ...inputVal,
                            kycApproved:'false'
                          }
                        }
                      
                        if(hostData.pepresult.resultCountPEP == '0'){
                          pep = "0";
                        } else {
                          pep = hostData.pepresult.resultCountPEP;
                        }
                        if(hostData.wlsresult.resultCountWLS == '0'){
                          watchlist = "0";
                        } else {
                          watchlist = hostData.wlsresult.resultCountWLS;
                        }
                        /* if(hostData.nmresult.resultCountNM == '0'){
                          negative_media = "0";
                        } else {
                          negative_media = hostData.nmresult.resultCountNM;
                        } */
                      
                        setPPChecks({//for use in secondary doc capture of rc
                          ...ppChecks,
                          us: us_citizen,
                          pep: pep,
                          wls: watchlist,
                          //nm: negative_media
                          //pep: "0",
                          //wls: "0",
                          //nm: "0"
                        });
                        
                        inputVal={
                          ...inputVal,
                          captureSelfiePassportChecks: {
                            ...ppChecks,
                            us: us_citizen,
                            pep: pep,
                            wls: watchlist,
                            //nm: negative_media
                          }
                        }
                      }

                      console.log('inputVal', inputVal);
                      setInputs({
                        ...inputs,
                        ...inputVal
                      });

                      let formdata = {token:token, entity_group:"documentation", application_id:applicationId, items:{
                        captureSelfiePassport: inputVal.captureSelfiePassport,
                        kycApproved: inputVal.kycApproved,
                        passportImg: inputVal.passportImg,
                        passportImgTimestamp: inputVal.passportImgTimestamp,
                        selfieImg: inputs.selfieImg,
                        selfieImgTimestamp: inputs.selfieImgTimestamp,
                        captureSelfiePassportChecks: inputVal.captureSelfiePassportChecks,
                        primaryDocument: inputs.primaryDocument
                      }};
                      console.log(formdata);

                      axios.post(serverConfig.api_base_url + "customer_entity_group_details_set",formdata)
                      .then(response=>{
                        console.log('customer_entity_group_details_set response', response);
                        if(response.data.status === 200) {
                          console.log('inputs', inputVal, inputs);
                          console.log(/* inputs.secondaryDocImg,  */inputVal.passportImg);
                          /* if(inputVal.passportImg !==null && inputVal.passportImg !==undefined){
                            setNextDisabled(false);
                          } else {
                            setNextDisabled(true);
                          } */
                          //setInputs({});

                          axios.post(serverConfig.api_base_url + "customer_kyc_data_set",{customer_name: UserService.getWelcomeName(), customer_email: UserService.getUsername(),
                            token: token,
                            application_id: applicationId,
                            title: "KYC Passed",
                            desc: submissionResponse.resultData.verificationResult,
                            kyc_data: submissionResponse
                          })
                          .then(response=>{
                            console.log('customer_kyc_data_set response', response);
                            if(response.data.status === 200) {
                              console.log("success");
                              kycStatusGet();
                            } else if(response.data.status === 407 || response.data.status === 301){
                              //handleLogout();
                            } else {
                              alert(response.data.message);
                            }
                          })
                          .catch(err=>console.log("error is",err));

                        } else if(response.data.status === 407){
                          //handleLogout();
                        } else {
                          alert(response.data.message);
                        }
                      })
                      .catch(err=>console.log("error is",err));

                      var docType;
                      if(inputs.primaryDocument === "Passport"){
                        if(extractedData?.idIssueCountry === "US"){
                          docType = "U.S. passport"
                        } else {
                          docType = "Foreign passport"
                        }
                      } else if(inputs.primaryDocument === "Driver's License"){
                        docType = "State issued driver's license"
                      } else if(inputs.primaryDocument === "State issued ID"){
                        docType = "State/local/tribe-issued ID"
                      }

                      var stateLookupList;
                      console.log(extractedData?.idCountry);
                      extractedData?.idCountry !== undefined && axios.post(serverConfig.api_base_url + "get_lookup_values",{entity_name:"state", entity_parent_value:countryLookup.filter(el => el.country_code_iso3 === (extractedData?.idCountry))[0].value})
                      .then(response=>{
                        console.log(response);
                        if(response.data.data !== null){
                          stateLookupList=response.data.data.sort((a, b) => a.value.localeCompare(b.value));

                          console.log(stateLookupList, extractedData?.idState);
                          console.log(extractedData?.idState !== undefined && stateLookupList?.filter(el => el.state_code === (extractedData?.idState)));
                          console.log(extractedData?.idState !== undefined && stateLookupList?.filter(el => el.state_code === (extractedData?.idState))[0]?.value);
                          
                          var fullName = extractedPersonalData?.name_doc?.split(' '),
                                    firstName = fullName[0],
                                    middleName = fullName.length > 2 ? fullName[fullName.length - 2] : undefined,
                                    lastName = fullName[fullName.length - 1];

                          //company applicant
                          /* let formdataCompanyApplicant = {token:token, entity_group:'company_applicant_information', application_id:applicationId, items:{
                            applicantIdentifyingDocumentImage: "idimage.jpeg",
                            applicantIdentifyingDocumentImageBase64: inputVal.passportImg,
                            applicantIndividualLastName: extractedPersonalData?.lastName_doc || extractedPersonalData?.lastName || lastName,
                            applicantFirstName: extractedPersonalData?.firstName_doc || extractedPersonalData?.firstName || firstName,
                            applicantMiddleName: extractedPersonalData?.middleName_doc || extractedPersonalData?.middleName || middleName,
                            applicantCurrentCountryJurisdiction: extractedData?.idCountry !== undefined ? countryLookup.filter(el => el.country_code_iso3 === (extractedData?.idCountry))[0].value : undefined,
                            applicantDocumentIdIssuingCountryJurisdiction: extractedData?.idCountry !== undefined ? countryLookup.filter(el => el.country_code_iso3 === (extractedData?.idCountry))[0].value : undefined,
                            applicantCurrentCountryJurisdictionCode: extractedData?.idCountry !== undefined ? extractedData?.idCountry : undefined,
                            applicantDocumentIdIssuingCountryJurisdictionCode: extractedData?.idCountry !== undefined ? extractedData?.idCountry : undefined,
                            applicantCurrentAddress: parsedAddress?.streetName,
                            applicantCurrentState: extractedData?.idState !== undefined ? stateLookupList?.filter(el => el.state_code === (extractedData?.idState))[0]?.value : undefined,
                            applicantDocumentIdIssuingState: extractedData?.idState !== undefined ? stateLookupList?.filter(el => el.state_code === (extractedData?.idState))[0]?.value : undefined,
                            applicantCurrentStateCode: extractedData?.idState,
                            applicantDocumentIdIssuingStateCode: extractedData?.idState,
                            applicantCurrentCity: parsedAddress?.province,
                            applicantCurrentZipcode: parsedAddress?.postalCode,
                            applicantDateOfBirth: (extractedPersonalData?.dob_doc)?.split("/").reverse().join("-") || (extractedPersonalData?.dob)?.split("/").reverse().join("-"),
                            applicantDocumentIdType: docType, 
                            applicantDocumentIdNumber: extractedData.idNumber
                          }};
                          console.log(formdataCompanyApplicant);
      
                          axios.post(serverConfig.api_base_url + "customer_entity_group_details_set",formdataCompanyApplicant)
                          .then(response=>{
                            console.log('customer_entity_group_details_set response', response);
                            if(response.data.status === 200) {
                              console.log('success');
                            } else if(response.data.status === 407){
                              //handleLogout();
                            } else {
                              alert(response.data.message);
                            }
                          })
                          .catch(err=>console.log("error is",err)); */

                          //authorized company representative
                          let formdataCompanyRepresentative = {token:token, entity_group:'authorized_company_representative', application_id:applicationId, items:{
                            representativeIdentifyingDocumentImage: "idimage.jpeg",
                            representativeIdentifyingDocumentImageBase64: inputVal.passportImg,
                            representativeIndividualLastName: extractedPersonalData?.lastName_doc || extractedPersonalData?.lastName || lastName,
                            representativeFirstName: extractedPersonalData?.firstName_doc || extractedPersonalData?.firstName || firstName,
                            representativeMiddleName: extractedPersonalData?.middleName_doc || extractedPersonalData?.middleName || middleName,
                            representativeCurrentCountryJurisdiction: extractedData?.idCountry !== undefined ? countryLookup.filter(el => el.country_code_iso3 === (extractedData?.idCountry))[0].value : undefined,
                            representativeDocumentIdIssuingCountryJurisdiction: extractedData?.idCountry !== undefined ? countryLookup.filter(el => el.country_code_iso3 === (extractedData?.idCountry))[0].value : undefined,
                            representativeCurrentCountryJurisdictionCode: extractedData?.idCountry !== undefined ? extractedData?.idCountry : undefined,
                            representativeDocumentIdIssuingCountryJurisdictionCode: extractedData?.idCountry !== undefined ? countryLookup.filter(el => el.country_code_iso3 === (extractedData?.idCountry))[0].country_code : undefined,
                            representativeCurrentAddress: parsedAddress?.streetName,
                            representativeCurrentState: extractedData?.idState !== undefined ? stateLookupList?.filter(el => el.state_code === (extractedData?.idState))[0]?.value : undefined,
                            representativeDocumentIdIssuingState: extractedData?.idState !== undefined ? stateLookupList?.filter(el => el.state_code === (extractedData?.idState))[0]?.value : undefined,
                            representativeCurrentStateCode: extractedData?.idState,
                            representativeDocumentIdIssuingStateCode: extractedData?.idState,
                            representativeCurrentCity: parsedAddress?.province,
                            representativeCurrentZipcode: parsedAddress?.postalCode,
                            representativeDateOfBirth: (extractedPersonalData?.dob_doc)?.split("/").reverse().join("-") || (extractedPersonalData?.dob)?.split("/").reverse().join("-"),
                            representativeDocumentIdType: docType, 
                            representativeDocumentIdNumber: extractedData.idNumber
                          }};
                          console.log(formdataCompanyRepresentative);
      
                          axios.post(serverConfig.api_base_url + "customer_entity_group_details_set",formdataCompanyRepresentative)
                          .then(response=>{
                            console.log('customer_entity_group_details_set response', response);
                            if(response.data.status === 200) {
                              console.log('success');
                            } else if(response.data.status === 407){
                              //handleLogout();
                            } else {
                              alert(response.data.message);
                            }
                          })
                          .catch(err=>console.log("error is",err));
                        }
                      })
                      .catch(err=>console.log("error is",err));
                      
                      //payment
                      let formdataPayment = {token:token, entity_group:'payment', application_id:applicationId, items:{
                        contactLastName: extractedPersonalData?.lastName_doc || extractedPersonalData?.lastName,
                        contactFirstName: extractedPersonalData?.firstName_doc || extractedPersonalData?.firstName,
                        contactEmail: UserService.getUsername()
                      }};
                      console.log(formdataPayment);
                      axios.post(serverConfig.api_base_url + "customer_entity_group_details_set",formdataPayment)
                      .then(response=>{
                        console.log('customer_entity_group_details_set response', response);
                        if(response.data.status === 200) {
                          console.log('success for payment');
                        } else if(response.data.status === 407){
                          //handleLogout();
                        } else {
                          alert(response.data.message);
                        }
                      })
                      .catch(err=>console.log("error is",err));

                      setErrorPassport(false);
                      setStartPPValidation(false);
                      //setSuccessPassportDetails(false);
                      //setErrorPassportDetails(false);
                    }
                  }} 
                  verifyIdWithExternalDatabases={true} //for watchlist, NM, PEP etc 
                  colors={
                    {
                      loadingOverlay:{
                        continueBtn: {
                          backgroundColor:'var(--primary)',
                          textColor: '#000000'
                        }
                      }
                    }
                  }
                  geolocationEnabled={false}
                  geolocationRequired={false}
                  debugMode={true}
                />
              </div>}
            </>
          }
        </MobileView>
      </Container>
    </main>
  </div>);
}

export default KYCValidation;
