import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Accordion } from "react-bootstrap";
import axios from "axios";
import UserRegistration from "./UserRegistration.jsx";
import logoSvg from './../../assets/images/logo.svg';
import ContactUs from "../ContactUs.jsx";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const serverConfig = require("../../config/server.js");
const appConfig = require("../../config/config.js");

const RegisterLanding = () => {

  const stripePromise = loadStripe(appConfig.stripe_key);
  
  const searchParams = new URLSearchParams(window.location.search);
  
  const org_slug = searchParams.get("id");
  console.log(org_slug);

  const [isNext, setIsNext] = useState(false);

  const [logoBase64Text, setLogoBase64Text] = useState('');

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://js.hs-scripts.com/178283.js";
    script.type = "text/javascript";
    script.id="hs-script-loader"
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    axios.post(serverConfig.api_base_url + "get_org_logo_image",{org:org_slug})
    .then(response=>{
      console.log('get_org_logo_image response', response);
      if(response.data.status === 200) {
        setLogoBase64Text(response.data.logoBase64);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }, []);

  const [showQuestions, setShowQuestions] = useState(false);

  return (
    <div id="pageLanding">
      <header className="py-2">
        <Container>
          <Row className="align-items-center">
            <Col>
              <a href="/"><img src={logoSvg} alt="Logo" className="img-fluid" width="300" /></a>
            </Col>
            <Col>
              {logoBase64Text && <img src={logoBase64Text} alt="Logo" className="img-fluid ms-auto d-block" />}
            </Col>
          </Row>
        </Container>
      </header>
      <main>
        <Container>
          <Row>
            <Col lg={7}>
              <div className="img-content-wrap">
                <h1>Stay Compliant with FinCEN,Effortlessly</h1>
                <p className="mt-4"><u><strong>Partner with Manatoko BOIR</strong></u></p>
                <p>Navigating the complexities of Beneficial Ownership Information (BOI) reporting can be daunting for any business owner. With deadlines approaching, it's crucial to ensure compliance to avoid severe penalties. Our comprehensive guide simplifies the intricate requirements set by FinCEN, providing you with the clarity and tools needed to meet your obligations with ease.</p>
                <p className="mt-5"><u><strong>Why Choose Manatoko BOIR</strong></u></p>
                <ul>
                  <li><strong>Simplified Guidance</strong> Our user-friendly guide breaks down complex regulations into easy-to-understand steps, saving you time and effort.</li>
                  <li><strong>Seamless Filing</strong> Utilize our system-to-system API with FinCEN for direct and efficient electronic filing from your account.</li>
                  <li><strong>Stay Updated</strong> Utilize our system-to-system API with FinCEN for direct and efficient electronic filing from your account.</li>
                </ul>
              </div>
              <div class="faq-container">
                <Button variant="white-bordered" type="button" className="rounded-0 w-100 mb-4" onClick={() => setShowQuestions(!showQuestions)}>Questions</Button>
                {showQuestions && <section id="faq-content" className="accordion-white">
                  <Accordion alwaysOpen>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>What is Manatoko BOIR, and how does it help me?</Accordion.Header>
                      <Accordion.Body>Manatoko BOIR is here to make your life easier! It's an online platform designed by Amberoon Inc. to help you manage and file your Beneficial Ownership Information Reports(BOIR) with FinCEN using a system-to-system API. We take care of the complicated filing process, so you can focus on running your business while we handle the submission securely and efficiently.</Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>How can I get help using the platform?</Accordion.Header>
                      <Accordion.Body>We're here to support you! If you ever need help or guidance, just click the chat button in the bottom right corner of your screen.</Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Can I update my account information if something changes?</Accordion.Header>
                      <Accordion.Body>Absolutely! If any of your details change—like your business address or contact information—you can update it easily through the platform. Keeping everything up to date ensures your filings are always accurate. There is no charge for updates as long as your subscription is valid.</Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>What if I have more questions?</Accordion.Header>
                      <Accordion.Body>If you have more questions, please click on the chat button at the bottom right or send us email at Manatoko <a href="mailto:boir@amberoon.com">boir@amberoon.com</a></Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>How can I keep my Manatoko BOIR account secure?</Accordion.Header>
                      <Accordion.Body>It's important to keep your account safe! Make sure your username and password are strong and don't share them with anyone else. If you ever suspect that someone might be accessing your account without permission, let us know right away, and we'll help secure it.</Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>How much does it cost to use Manatoko BOIR?</Accordion.Header>
                      <Accordion.Body>Manatoko BOIR is a subscription service, and the cost is $149 for an annual subscription. This allows you unlimited updates to your FinCEN records for a year. We will bill the payment method you provided when you registered, and the subscription renews automatically every year unless you decide to cancel it at any time.</Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                      <Accordion.Header>How do I set up an account on Manatoko BOIR?</Accordion.Header>
                      <Accordion.Body>Setting up an account is simple! We'll ask for some basic information, like your name, email,phone number, and a few details about your business. Once you're registered, you'll be ready to start managing your BOIR filings with ease.</Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                      <Accordion.Header>Can I cancel my Manatoko BOIR account?</Accordion.Header>
                      <Accordion.Body>Yes, you can! If you decide to cancel your subscription, just click on the chat bot in the bottom right corner of your screen, and we'll help you terminate it. Keep in mind, if you've already submitted filings, we may need to keep some of your data to comply with legal requirements.</Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </section>}
              </div>
            </Col>
            <Col lg={5}>
              {isNext === false && <>
                <div className="mb-3"><img src={require("./../../assets/images/landing.jpg")} className="img-fluid" /></div>
                <p>Get started now with our automated system that will keep you compliant.</p>
              </>}
              <Elements stripe={stripePromise}><UserRegistration sendIsNextVal={setIsNext} /></Elements>
              <p className="mt-2 mb-0 text-secondary"><i>In light of a recent federal court order, reporting companies are not currently required to file beneficial ownership information with FinCEN and are not subject to liability if they fail to do while the order remains in force. However, reporting companies may continue to voluntarily submit beneficial ownership information reports. More information is available on FinCEN's website at <a href="https://www.fincen.gov/boi" target="_blank">https://www.fincen.gov/boi</a></i></p>
            </Col>
          </Row>
        </Container>

        <ContactUs />
      </main>
    </div>
  );
};

export default RegisterLanding;